import React, { useState, useEffect } from 'react';

import Phone from './Phone';
import { iOS as check } from '../../utils/general';
import appstore from '../../images/home/testimonials/app_store_border.png';
import playstore from '../../images/home/testimonials/play_store_border.png';
import { extLinkClick } from '../../utils/general';
import { SignUpField, FormHeader, FormBody, FormText, ComingText, PhoneWrapper, StoreImg } from '../../styles';

export const Signup = ({ isOpen, switc }) => {
	const [iOS, setiOS] = useState(false);

	useEffect(() => {
		setiOS(check());
	}, []);

	return (
		<SignUpField className={isOpen}>
			<FormText>
				{switc ? <FormHeader>Switch to Envel now</FormHeader> : <FormHeader>Join Envel now</FormHeader>}
				{/* <FormTitle>
					launching this fall! <Emoji symbol="🚀" label="Rocket" />
				</FormTitle> */}
				<FormBody>
					Takes less than 3 minutes. Won’t affect your credit score! No monthly fees or minimum balance.
				</FormBody>
			</FormText>
			<ComingText iOS={iOS}>Get it now:</ComingText>
			<StoreImg
				iOS={iOS}
				src={appstore}
				alt="appstore"
				onClick={() => extLinkClick('https://envel.app.link/SPNYWPJVDcb')}
			/>
			<StoreImg
				iOS={iOS}
				src={playstore}
				alt="playstore"
				onClick={() => extLinkClick('https://envel.app.link/SPNYWPJVDcb')}
			/>
			<PhoneWrapper className="signUp">
				<Phone />
			</PhoneWrapper>
		</SignUpField>
	);
};

export default Signup;
