import React, { useState, useEffect } from 'react';
import SlideToggle from 'react-slide-toggle';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import SignUp from '../components/website/SignUp';
import {
	FAQHeader,
	FAQTitle,
	FAQContainer,
	FAQBody,
	FAQTop,
	FAQArrow,
	Header,
	FAQTable,
	FAQRow,
	Body,
} from '../styles';

const FAQ = ({ data }) => {
	const faqList = data.allGoogleFaqSheet.edges;
	const [faq, setFaq] = useState([]);
	const [selected, setSelected] = useState({ i: null, j: null });

	// transforming faq data in format needed for display in jsx.
	const refineFaq = faqList => {
		const refinnedFaq = [];
		let title = '';
		let questions = [];
		faqList.forEach(({ node: { heading, question, answer } }, i) => {
			if (!title) {
				title = heading;
				questions.push({ question, answer });
			} else if (heading === title) {
				questions.push({ question, answer });
				if (i === faqList.length - 1) {
					refinnedFaq.push({ heading: title, questions });
				}
			} else {
				refinnedFaq.push({ heading: title, questions });
				title = heading;
				questions = [{ question, answer }];
				if (i === faqList.length - 1) {
					refinnedFaq.push({ heading, questions });
				}
			}
		});
		setFaq(refinnedFaq);
	};

	useEffect(() => {
		refineFaq(faqList);
	}, []);

	return (
		<Layout>
			<MetaData
				title="Envel - FAQ"
				description="Frequently Asked Questions about who we are and our Envel app features."
				image="images/social/social.png"
			/>
			<FAQContainer>
				<Header center>FAQ</Header>

				{faq.map(
					({ heading, questions }, i) =>
						heading !== 'Front' && (
							<div key={heading + i}>
								<FAQHeader className={heading === 'About us'}>{heading}</FAQHeader>
								{questions.map(({ question, answer }, j) => (
									<SlideToggle collapsed={true} key={question + i}>
										{({ toggle, setCollapsibleElement, toggleState }) => {
											if (toggleState === 'EXPANDED' && (selected.i !== i || selected.j !== j)) toggle();
											return (
												<>
													<FAQTop
														onClick={e => {
															setSelected({ i: i, j: j });
															toggle();
														}}
													>
														<FAQTitle>{question}</FAQTitle>
														<FAQArrow toggle={toggleState} />
													</FAQTop>
													<div ref={setCollapsibleElement}>
														{answer === 'table' ? (
															<>
																<FAQBody>
																	Say goodbye to, minimum balances, service fees and monthly fees. Make instant, free
																	money transfers in Envel.
																</FAQBody>
																<FAQTable>
																	<FAQRow>
																		<Body>Monthly account fee</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Up to 99 envelopes (bank accounts)</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Minimum deposit to open an account</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Minimum balance required</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Money transfer to Envel friends</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Domestic card transaction</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Replacement debit card fee*</Body>
																		<Body>$10</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>ATM in MoneyPass network fees**</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>ATM out of MoneyPass network fee</Body>
																		<Body>$2.50</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Support fee</Body>
																		<Body>$0</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Envel Investments monthly subscription fee</Body>
																		<Body>$1.99</Body>
																	</FAQRow>
																	<FAQRow>
																		<Body>Foreign transaction fee (charge by Visa, not Envel)</Body>
																		<Body>1.0%</Body>
																	</FAQRow>
																</FAQTable>
																<FAQBody color="rgba(150, 150, 150, 0.8)">
																	*Fee for each requested replacement of a card or debit access. The fee does not apply
																	when we replace a card upon its expiration date.
																	<br />
																	**All ATMs inside the MoneyPass and Visa networks +- 38,000 ATMs
																</FAQBody>
															</>
														) : (
															<FAQBody dangerouslySetInnerHTML={{ __html: answer }} />
														)}
													</div>
												</>
											);
										}}
									</SlideToggle>
								))}
							</div>
						)
				)}
			</FAQContainer>
			<SignUp />
		</Layout>
	);
};

export default FAQ;

export const faqQuery = graphql`
	query {
		allGoogleFaqSheet(sort: { order: ASC, fields: heading }) {
			edges {
				node {
					heading
					question
					answer
				}
			}
		}
	}
`;
